import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Information from "../components/information"

import ARROW from "../images/arrow.svg"
import IMG_01 from "../images/index-img-01.jpeg"
import IMG_02 from "../images/index-img-02.jpeg"

import * as styles from "../styles/index.module.scss"

const Home = () => {
  return (
    <Layout>
      <Seo title="ホーム" description="このサイトはブロックチェーンコネクトのコーポレートサイトです。" type="website" />
      <section className={styles.hero} style={{ backgroundImage: `linear-gradient(rgba(76, 146, 187, 0.39), rgb(60 117 228 / 35%)),url(${IMG_01})` }}>
        <div className={styles.inner}>
          <div className={styles.content}>
            <h2>
              ブロックチェーンで、<br />
              ミライを変える。
            </h2>
          </div>
        </div>
      </section>

      <section className={styles.vision} id="vision">
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">VISION</h2>
            </div>
            <div className={styles.body}>
              <p className={styles.ttl}>
                ブロックチェーンを、<br className="sp" />より身近に。<br />
                我々にしかできないこと
              </p>
              <div className={styles.txt}>
                <p>
                  「ブロックチェーン」＝「ビットコインなどの仮想通貨」という認識をもたれがちですが、ビットコインはブロックチェーン技術を使った一商品に過ぎません。つまりブロックチェーン技術は他の商品やサービスにも活用でき、現在では様々なビジネスソリューションとして採用されています。弊社は日本有数のブロックチェーン技術があるからこそ様々なご提案でビジネスを加速させます。
                  </p>
                <div className="cBtn">
                  <Link to="/company">
                    ABOUT US
                    <span style={{ backgroundImage: `url(${ARROW})` }}>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={styles.bgImg} style={{ backgroundImage: `url(${IMG_02})` }}>
        <div className={styles.inner}>

        </div>
      </div>

      <section className={styles.service}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2>
                <p className={`${styles.bgTxt} alphabet`}>service</p>
                <div className={`${styles.lead} alphabet`}>CAPABILITIES</div><br/>
                対応できる技術領域
              </h2>
              <p>弊社のサービス内容について</p>
            </div>
            <div className={styles.body}>
              <div className={styles.links}>
                <Link to="/solution2.0">
                  <div>
                    <p className={`${styles.ttl} alphabet`}>WEB2.0</p>
                    <p>WEBサイトやECサイトなど<br/>フロントエンド開発～システム開発まで対応可能です。</p>
                  </div>
                  <div className={styles.arrow}>
                    <img src={ARROW}></img>
                  </div>
                </Link>
                <Link to="/solution3.0">
                  <div>
                    <p className={`${styles.ttl} alphabet`}>WEB3.0</p>
                    <p>ブロックチェーン技術に関する<br/>ほぼすべてのサービスを網羅しております。</p>
                  </div>
                  <div className={styles.arrow}>
                    <img src={ARROW}></img>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Information />
      
    </Layout>
  )
}

export default Home

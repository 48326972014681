// extracted by mini-css-extract-plugin
export var arrow = "index-module--arrow--jmlqQ";
export var bgImg = "index-module--bgImg--w9yl8";
export var bgTxt = "index-module--bgTxt--KKt84";
export var body = "index-module--body--7CUHK";
export var content = "index-module--content--h2P2f";
export var head = "index-module--head--IZQ1y";
export var hero = "index-module--hero--Io3wv";
export var inner = "index-module--inner--LkPAb";
export var lead = "index-module--lead--A08Rk";
export var links = "index-module--links--Q9v5Q";
export var service = "index-module--service--U2DtB";
export var ttl = "index-module--ttl--R2hQe";
export var txt = "index-module--txt--t7XGU";
export var vision = "index-module--vision--wZZj+";